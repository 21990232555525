import React from "react";
import "./heroSection.css";

const HeroSection = () => {
    return (
        <div className="hero-section1">
            {/* <img className="hero-float-logo" src="/images/saravana/saravana_about_1.png" alt="banner" /> */}
            {/* <h1 className="hero-float-title">Sree Saravana Project's</h1> */}
        </div>
    );
};

export default HeroSection;
